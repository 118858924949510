$bu: ocr;
@import '../../../custom';

.pagination {
  display: flex;

  .pagination-item {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    border: 0;
  }

  .decrement-button,
  .increment-button {
    background-color: $white;
    color: $secondary;
    border: solid 1px $e4;
    border-radius: 0.5rem;

    &.disabled {
      background-color: $secondary;
      color: $white;
    }
  }

  .current-page {
    color: $primary;

    &.disabled {
      opacity: 1;
    }
  }

  .select-page-button {
    background-color: $white;
    color: $secondary;
    border: solid 1px $e4;

    &:focus {
      background-color: $white;
      color: $secondary;
      border: solid 1px $e4;
    }
  }

  .spread-container {
    border: solid 1px $e4;
    color: $secondary;
    border-radius: 0.5rem;
  }
}
