$bu: ocr;
.candidates-list {
  .candidates-filter-tab-content {
    min-width: 72px;
  }

  .candidates-filter-main-tab,
  .candidates-filter-main-tab-label {
    min-width: 15rem;
    max-width: 15rem;
  }
}
