$bu: ocr;
@import '../../custom';

.nav-container {
  .separator {
    width: 100%;
    height: 2px;
    background-color: $dropdown-divider-bg;
  }
  .navbar-logo-container {
    display: flex;

    @if ($bu == ocr) {
      margin-right: 4.25rem;
    }

    @if ($bu == ci) {
      margin-right: 3.8rem;
    }

    &--sub-page {
      @if ($bu == ci) {
        margin-right: 7rem;
      }

      img {
        margin: 0 auto;
      }
    }
    .site-heading {
      border-left: 1px solid rgba(#000, 0.37);
      height: 24px;
      margin-left: 24px;
      padding-left: 24px;
      align-self: center;
      color: #000;

      @if ($bu == ocr) {
        border-color: $primary37;
        color: $primary;
      }
    }
  }

  .text-redonblack {
    color: $redonblack;
    &:hover {
      color: $redonblack;
    }
  }
}
