$bu: ocr;
@import '../../../custom';

.checkbox-explicit {
  > * + * {
    margin-left: 8px;
  }
  span {
    background: $ddd;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.59);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 8px;
    text-transform: uppercase;
  }
  .yes {
    &.active {
      background: $success;
    }
  }
  .no {
    &.active {
      background: $danger;
    }
  }
  .active {
    color: white;
  }
  &.untouched {
    .no {
      &.active {
        color: rgba(0, 0, 0, 0.59);
        background: $ddd;
      }
    }
  }
}

.checkbox-internal {
  cursor: pointer;
  color: white;
  background-color: $ddd;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    background-color: $success;
  }
}
