$bu: ocr;
@import '../../custom';

.unit-progress {
  .black-color {
    color: $body-color;
  }

  .transition-none {
    .progress-bar {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
  }

  .progress-slim-1 {
    height: 6px;
  }

  .project-row {
    background-color: $light !important;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    @include media-breakpoint-up(sm) {
      background-color: $white !important;
      margin-left: 0;
      margin-right: 0;
    }
    @include media-breakpoint-down(xs) {
      border: 0;
      border-radius: 0;
    }
    &:hover {
      background-color: $light !important;
    }

    .unit-progress-notification {
      height: 42px;
    }
  }

  .rounded-bottom-sm-down {
    @include media-breakpoint-down(xs) {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
