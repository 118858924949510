$bu: ocr;
@import '../../custom';

.hoc-approval-modal {
  min-width: 830px;

  .modal-header-cj button.close span {
    color: $black37;
    font-size: 2.5rem;
  }

  .ci-declarations {
    font-size: 16px;
    > * {
      margin-bottom: 36px;
    }
    h6 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    ul {
      padding-left: 15px;
    }
    li {
      padding-left: 25px;
      list-style: none;
      position: relative;
      margin-bottom: 24px;
      &:before {
        background: black;
        border-radius: 50%;
        content: '';
        display: block;
        height: 6px;
        left: -6px;
        position: absolute;
        top: calc(50% - 3px);
        width: 6px;
      }
    }
  }

  .declaration-confirmation {
    display: inline-flex;
    align-items: center;

    .checkbox-internal {
      margin-right: 16px;
      width: 34px;
      height: 24px;
    }
  }
}
