$bu: ocr;
@import '../../../custom';

.management-user {
  border: 1px solid $ddd;
  border-radius: $border-radius;
  font-weight: bold;

  .user-email {
    font-weight: normal;
    font-size: 16px;
    color: $secondary;
  }

  .send-reminder {
    display: inline-flex;
    align-items: center;
    color: $secondary;
  }

  .status-label {
    border-radius: $border-radius;
    text-transform: uppercase;
    border: 1px solid $secondary;
    color: $secondary;

    &.confirmed {
      background-color: $primary;
      color: white;
      border: 0;
    }
  }
}
