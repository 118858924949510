$bu: ocr;
@import '../../../../../custom';

.modal-header-cj {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.modal-header {
  .close {
    color: $secondary;
  }
}
