$bu: ocr;
@import '../../custom';

.unit-code-container {
  margin-top: 70px;
  .title {
    font-weight: bold;
  }

  .unit-code-info {
    padding-bottom: 40px;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;

    .unit-card {
      flex: 50%;
      margin-bottom: 24px;
    }
  }
}
