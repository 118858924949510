$bu: ocr;
@import '../../../../custom';

.row.sorter-container {
  margin: 0;

  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  font-weight: bold;

  border-top: $candidate-list-border;
  border-right: $candidate-list-border;
  border-left: $candidate-list-border;

  .text-center .sorter-label.sortable {
    position: relative;
    left: 3px;
  }

  .sorter-label {
    &.sortable {
      cursor: pointer;
    }
    .sort-up {
      position: absolute;
    }
  }

  &.border-bottom {
    border-radius: $border-radius;
  }
}
