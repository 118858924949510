$bu: ocr;
@import '../../custom';

.centre-unit {
  .cust-margin {
    margin-left: 36px;
  }

  .label-dropdown {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      color: $primary !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }

  .top-border {
    border-top: 2px solid white;
  }
}
