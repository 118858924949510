$bu: ocr;
@import '../../custom';

.specification-files {
  font-size: $font-medium;
  margin-top: 70px;

  .header {
    font-size: $font-large;
  }

  .search-files {
    padding-top: 40px;
    width: 50%;
  }

  .files-list-container {
    margin-top: 24px;

    .search-info {
      padding-top: 25px;

      div {
        padding-top: 15px;
      }

      .amount-files {
        flex-grow: 1;
        font-weight: 600;
      }
    }

    .file-row {
      height: 64px;
      border-bottom: solid 1px $border-grey;
      display: flex;
      align-items: center;

      .file-image {
        flex: 0;
        font-size: 24px;
      }

      .file-name {
        flex: 1;
        padding-left: 14px;
        font-size: $font-small;
        font-weight: bold;
      }

      .download-button {
        margin-right: 14px;
        flex: 0;
        align-items: center;
        display: flex;

        .download-icon {
          font-size: $font-small;
          margin-right: 8px;
        }
      }
    }
  }
}
