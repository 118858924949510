$bu: ocr;
@import '../../../custom';

.pagination-container {
  display: flex;
  align-items: center;
  margin-top: 32px;

  .page-limit-dropdown {
    background-color: $white !important;
    border: 0;
    color: $black !important;
    box-shadow: none !important;
    display: flex;
    font-weight: 400;

    .selected-size {
      padding-left: 14px;
      color: $primary;
      font-weight: bold;

      .dropdown-icon {
        margin-left: 8px;
      }
    }
  }

  .dropdown-menu {
    min-width: 91px;

    .selected-page-size {
      background-color: $primary;
    }

    .dropdown-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .selected-page-size-icon {
        margin-right: 10px;
      }

      .page-limit {
        width: 20px;
        font-weight: 600;
      }
    }
  }
}
