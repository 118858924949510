$bu: ocr;
@import '../../../../custom';

.grade-selector {
  justify-content: center;
  font-weight: bold;

  .grade {
    $border-style: 1px solid $e4;
    $size: 48px;
    flex-basis: auto;
    flex-grow: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    height: $size;
    width: auto;
    min-width: $size;

    background-color: white;

    border-top: $border-style;
    border-bottom: $border-style;
    border-left: $border-style;

    &:last-child {
      border-right: $border-style;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.active,
    &.pending {
      background-color: $primary;
      color: white;

      &.disabled {
        background-color: $secondary;
      }
    }
  }

  &.grade-pending .grade.active {
    opacity: 0.65;
  }
}
