$bu: ocr;
@import '../../../custom';

.card.results-card__container {
  background: $light;
  border: none;

  max-width: 34em;

  .results-card__body {
    padding: 2em;
    height: 16.75rem;
    max-height: 16.75rem;
  }

  .results-card__title {
    color: $primary;
    font-weight: bold;
    min-height: 3.25rem;

    @include media-breakpoint-down(md) {
      font-size: 0.975rem;
    }
  }

  .results-card__subtitle {
    min-height: 4.5rem;

    @include media-breakpoint-down(md) {
      font-size: 0.975rem;
    }
  }

  .results-card__text {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-weight: bold;
    font-size: 0.9em;

    padding: 1rem 0 0 0;

    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
    }
    .results-card__button {
      background-color: $primary;
      border-color: $primary;

      padding: 0.75em 1.1em;
      margin-left: 2rem;

      .results-card__button-icon {
        margin-left: 0.5em;
      }
    }
  }
}
